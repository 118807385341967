import React, { useState, useEffect } from "react";
import { Table, Card, Space, Upload } from "lib";
import {
  Button,
  DatePicker,
  Form,
  Input,
  message,
  Modal,
  Switch,
  TablePaginationConfig,
  Typography,
  Select
} from "antd";
import { Layout } from "layout";
import {
  ClientGroupBriefDto,
  ClientGroupsClient,
  ClientBriefDto,
  ClientsClient,
  RegistrationPeriodsClient,
  RegistrationPeriodDto,
} from "@api";
import { showError, Download } from "@action";
import { FormattedMessage } from "react-intl";
import { PlusOutlined } from "@ant-design/icons";
import { ACTION, SECTION, SubpageAuth } from "@services/auth";
import { navigate } from "gatsby";
import QRCode from "qrcode";
import { useLocation } from "@reach/router";
import moment from "moment";
import ClientGroupDetailMenu from "@components/Admin/ClientGroup/ClientGroupDetailMenu";
import Header from "@components/Admin/ClientGroup/Header";
import { RcFile } from "antd/lib/upload";
const { Option } = Select;

const defaultOrderBy = "startOn";
const defaultSortOrder = "descend";
const defaultPagination: TablePaginationConfig = {
  current: 1,
  pageSize: 10,
position: ["topRight", "bottomRight"],
};

function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}
interface Params {
  id: number;
}
interface Props {
  params: Params;
}

const GroupLevelRegistrationPeriodPage = ({ params }: Props) => {
  const auth = new SubpageAuth(
    SECTION.Admin,
    "ClientGroups",
    "RegistrationPeriod"
  );
  if (auth.cannot(ACTION.Access)) {
    navigate("/403/");
    return <></>;
  }

  const groupId = params.id;
  const [RegistrationPeriodId, setRegistrationPeriod] = useState(0);

  const location = useLocation();

  const [modal, modalContextHolder] = Modal.useModal();
  const [messageApi, messageContextHolder] = message.useMessage();
  const [createForm] = Form.useForm();
  const [editForm] = Form.useForm();

  const registrationPeriodsClient = new RegistrationPeriodsClient();
  const clientGroupsClient = new ClientGroupsClient();
  const clientsClient = new ClientsClient();

  const [createModalVisible, setCreateModalVisible] = useState(false);
  const [qrcodeModalVisible, setQrCodeModalVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);

  const [qrCodeDataUrl, setQrCodeDataUrl] = useState("");

  const [state, setState] = useState({
    items: [] as RegistrationPeriodDto[],
    pagination: defaultPagination,
    loading: false,
  });

  const [clientGroups, setClientGroups] = useState([] as ClientGroupBriefDto[]);
  const [clients, setClients] = useState([] as ClientBriefDto[]);

  
  const [filter, setFilter] = useState({
    isHasConfirmCode: undefined as boolean | undefined
  });

  async function fetchItems(params: any = { pagination: state.pagination }) {
    setState({
      ...state,
      loading: true,
    });
    const res =
      await registrationPeriodsClient.getRegistrationPeriodsWithPagination(
        undefined,
        groupId,
        filter.isHasConfirmCode,
        params.pagination.current,
        params.pagination.pageSize,
        capitalizeFirstLetter(params.sortField ?? defaultOrderBy),
        (params.sortOrder ?? defaultSortOrder) == "ascend"
      );
    setState({
      ...state,
      items: res.items ?? [],
      pagination: {
        ...params.pagination,
        total: res.totalCount,
      },
      loading: false,
    });
  }

  async function fetchClients() {
    // if (getIsClientAdmin() == true || getIsDistributor() == true) {
    //   setIsLimitedToOwnGroup(true);
    //   return;
    // }

    setState({
      ...state,
      loading: true,
    });
    const data = await clientsClient.getClientsWithPagination(
      undefined,
      undefined,
      undefined,
      "Name",
      true
    );
    setClients(data?.items?.filter((x) => !!x.name) as ClientBriefDto[]);
    setState({
      ...state,
      loading: false,
    });
  }

  async function fetchClientGroups() {
    if (auth.cannot(ACTION.List, "RegistrationPeriod")) return;

    setState({
      ...state,
      loading: true,
    });
    const data = await clientGroupsClient.getClientGroupsWithPagination(
      undefined,
      undefined,
      undefined,
      "Name",
      true
    );
    setClientGroups(
      data?.items?.filter((x) => !!x.name) as ClientGroupBriefDto[]
    );
    setState({
      ...state,
      loading: false,
    });
  }

  async function handleTableChange(
    pagination: TablePaginationConfig,
    filters: any,
    sorter: any
  ) {
    await fetchItems({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination,
      ...filters,
    });
  }

  function OpenModalAddRegistrationPeriod() {
    setCreateModalVisible(true);
    createForm.setFieldsValue({ clientGroupId: groupId });
  }

  async function handleAddQrCode() {
    try {
      const values = await createForm.validateFields();

      if (values) {
        const hide = messageApi.loading(
          <FormattedMessage id="StaticCreatingMessage" />
        );
        try {
          await registrationPeriodsClient.create(values);
          messageApi.success(
            <FormattedMessage id="StaticCreateSuccessMessage" />
          );
          setCreateModalVisible(false);
          createForm.resetFields();
          await fetchItems();
        } catch (err) {
          showError(err);
        } finally {
          hide();
        }
      }
    } catch (err) {
      /* form error block*/
    }
  }

  function handleCancelAddQrCode() {
    setCreateModalVisible(false);
    createForm.resetFields();
  }

  function handleDownloadQrCode() {
    const a = document.createElement("a");
    a.href = qrCodeDataUrl;
    a.download = "QrCode.png";
    a.click();
  }

  function handleShowQrCode(passphrase: string) {
    // add qr code logics
    const qrCodeLink = "https://" + location.host + "/register/" + passphrase;

    QRCode.toDataURL(qrCodeLink)
      .then((url) => {
        setQrCodeDataUrl(url);
        setQrCodeModalVisible(true);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function OpenModalEditQrCode(record: RegistrationPeriodDto) {
    const start = moment(record.startOn);
    const end = moment(record.endOn);
    editForm.setFieldsValue({ ...record, startOn: start, endOn: end });
    setEditModalVisible(true);
  }

  async function handleEditQrCode() {
    try {
      const values = await editForm.validateFields();
      if (values) {
        const hide = messageApi.loading(
          <FormattedMessage id="StaticUpdatingMessage" />
        );
        try {
          await registrationPeriodsClient.update(values.id, values);
          messageApi.success(
            <FormattedMessage id="StaticUpdateSuccessMessage" />
          );
          setEditModalVisible(false);
          editForm.resetFields();
          await fetchItems();
        } catch (err) {
          showError(err);
        } finally {
          hide();
        }
      }
    } catch (err) {
      /* form error block */
    }
  }

  function handleCancelEditQrCode() {
    setEditModalVisible(false);
    editForm.resetFields();
  }

  function handleDeleteQrCode(id: number) {
    modal.confirm({
      title: <FormattedMessage id="StaticConfirmDeactivationMessage" />,
      okText: <FormattedMessage id="StaticYesLabel" />,
      cancelText: <FormattedMessage id="StaticNoLabel" />,
      async onOk() {
        const hide = messageApi.loading(
          <FormattedMessage id="StaticUpdatingMessage" />
        );
        try {
          await registrationPeriodsClient.delete(id);
          messageApi.success(
            <FormattedMessage id="StaticUpdateSuccessMessage" />
          );
          fetchItems();
        } catch (err) {
          showError(err);
        } finally {
          hide();
        }
      },
    });
  }

  function handleUploadConfirmCodeOnChange(id: number) {
    setRegistrationPeriod(id);
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };

  
  async function handleCreateConfirmCode(f: RcFile) {
    const hide = messageApi.loading(
      <FormattedMessage id="StaticUploadingMessage" />,
      0
    );
    try {
      await registrationPeriodsClient.createConfirmCodes(RegistrationPeriodId,{ data: f, fileName: f.name });
      fetchItems();
      messageApi.success(<FormattedMessage id="StaticUploadSuccessMessage" />);
      return f.name;
    } catch (err) {
      showError(err);
    } finally {
      hide();
    }
  }

  async function handleExportConfirmCode(id: number) {
    const hide = messageApi.loading(
      <FormattedMessage id="StaticPrepareDownloadMessage" />,
      0
    );
    try {
      const res = await registrationPeriodsClient.downloadConfirmCodes(id);
      return Download(res);
    } catch (err) {
      showError(err);
    } finally {
      hide();
    }
  }

  
  async function handleUpdateConfirmCode(f: RcFile) {
    const hide = messageApi.loading(
      <FormattedMessage id="StaticUploadingMessage" />,
      0
    );
    try {
      await registrationPeriodsClient.updateConfirmCodes(RegistrationPeriodId,{ data: f, fileName: f.name });
      fetchItems();
      messageApi.success(<FormattedMessage id="StaticUploadSuccessMessage" />);
      return f.name;
    } catch (err) {
      showError(err);
    } finally {
      hide();
    }
  }
  
  function handleCancelDownloadQrCode() {
    setQrCodeModalVisible(false);
  }

  
  function handleChangeIsHasConfirmCode(value: boolean) {
    setFilter((prev) => ({ ...prev, isHasConfirmCode: value }));
  }

  
  useEffect(() => {
    fetchItems();
  }, [filter]);

  const columns = [
    // {
    //   title: "ID",
    //   dataIndex: "id",
    //   key: "id",
    //   sorter: true,
    //   defaultSortOrder,
    // },
    // {
    //   title: "Client Group Name",
    //   dataIndex: ["clientGroup", "name"],
    //   key: "clientGroup",
    // },
    {
      title: <FormattedMessage id="ClientGroupDetailRegistrationPeriodTableHeaderStartOn" />,
      dataIndex: "startOn",
      key: "startOn",
      sorter: true,
      defaultSortOrder,
      render: (text: any, record: RegistrationPeriodDto) =>
        record.startOn?.toLocaleString(),
    },
    {
      title: <FormattedMessage id="ClientGroupDetailRegistrationPeriodTableHeaderEndOn" />,
      dataIndex: "endOn",
      key: "endOn",
      sorter: true,
      render: (text: any, record: RegistrationPeriodDto) =>
        record.endOn?.toLocaleString(),
    },
    {
      title: <FormattedMessage id="ClientGroupDetailRegistrationPeriodTableHeaderQuota" />,
      dataIndex: "quota",
      key: "quota",
      sorter: true,
    },
    {
      title: <FormattedMessage id="ClientGroupDetailRegistrationPeriodTableHeaderRegistrationsCount" />,
      dataIndex: "registrationsCount",
      key: "registrationsCount",
      sorter: true,
    },
    {
      title: <FormattedMessage id="ClientGroupDetailRegistrationPeriodTableHeaderHasConfirmCode" />,
      dataIndex: "hasConfirmCode",
      key: "hasConfirmCode",
      sorter: true,
      render: (b: boolean) => <>{String(b)}</>,
    },
    {
      title: <FormattedMessage id="ClientGroupDetailRegistrationPeriodTableHeaderHasEmailNotification" />,
      dataIndex: "hasEmailNotification",
      key: "hasEmailNotification",
      sorter: true,
      render: (b: boolean) => <>{String(b)}</>,
    },
    {
      title: <FormattedMessage id="ClientGroupDetailRegistrationPeriodTableHeaderHasLoginToken" />,
      dataIndex: "hasLoginToken",
      key: "hasLoginToken",
      sorter: true,
      render: (b: boolean) => <>{String(b)}</>,
    },
    {
      title: <FormattedMessage id="ClientGroupDetailRegistrationPeriodTableHeaderLink" />,
      dataIndex: "passphrase",
      key: "passphrase",
      render: (text: any) => (
        <>
          <Typography.Text copyable>
            {"https://" + location.host + "/register/" + text}
          </Typography.Text>
        </>
      ),
    },
    {
      title: <FormattedMessage id="ClientGroupDetailRegistrationPeriodTableHeaderActivate" />,
      dataIndex: "isEnabled",
      key: "isEnabled",
      sorter: true,
      render: (b: boolean) => <>{String(b)}</>,
    },
    {
      title: <FormattedMessage id="ClientGroupDetailRegistrationPeriodTableHeaderAction" />,
      render: (text: any, record: RegistrationPeriodDto) => (
        <Space wrap>
          <Space>
          {auth.can(ACTION.Read, "RegistrationPeriod") ? (
            <Button
              type="primary"
              onClick={() => handleShowQrCode(record.passphrase as string)}
            >
              <Space>
                <FormattedMessage id="RegistrationPeriodRegistrationPeriodTableReadButton" />
              </Space>
            </Button>
          ) : null}
          {auth.can(ACTION.Edit, "RegistrationPeriod") ? (
            <Button type="primary" onClick={() => OpenModalEditQrCode(record)}>
              <Space>
                <FormattedMessage id="RegistrationPeriodRegistrationPeriodTableEditButton" />
              </Space>
            </Button>
          ) : null}
          {auth.can(ACTION.Delete, "RegistrationPeriod") ? (
            <Button
              type="primary"
              onClick={() => handleDeleteQrCode(record.id as number)}
            >
              <Space>
                <FormattedMessage id="StaticDeactivateButton" />
              </Space>
            </Button>
          ) : null}
          </Space>
          <Space>
          {auth.can(ACTION.Edit, "RegistrationPeriod") && record.hasConfirmCode ? (
            <Upload action={handleCreateConfirmCode} 
            beforeUpload={() =>handleUploadConfirmCodeOnChange(record.id as number)}
            data={{id :record.id}}
            >
              <Button type="primary">
                <FormattedMessage id="RegistrationPeriodUploadNewConfirmCode" />
              </Button>
            </Upload>
          ) : null}
          {auth.can(ACTION.Edit, "RegistrationPeriod") && record.hasConfirmCode ? (
            <Button
              type="primary"
              onClick={() => handleExportConfirmCode(record.id as number)}
            >
              <Space>
                <FormattedMessage id="RegistrationPeriodExportConfirmCode" />
              </Space>
            </Button>
          ) : null}
          {auth.can(ACTION.Edit, "RegistrationPeriod") && record.hasConfirmCode ? (
            <Upload action={handleUpdateConfirmCode} 
            beforeUpload={() =>handleUploadConfirmCodeOnChange(record.id as number)}
            data={{id :record.id}}
            >
              <Button type="primary">
                <FormattedMessage id="RegistrationPeriodUpdateConfirmCode" />
              </Button>
            </Upload>
          ) : null}
          </Space>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    fetchClients();
  }, []);
  useEffect(() => {
    fetchClientGroups();
  }, [clients]);
  useEffect(() => {
    fetchItems();
  }, [clientGroups]);

  return (
    <Layout title="ClientGroupRegistrationPeriodNavigationTitle">
      <Card>
        <Space direction="vertical" size="middle">
          <Header id={groupId}></Header>
          <ClientGroupDetailMenu id={groupId} />
          <Space justify="space-between">
            <Space justify="flex-start">
              <Form>{/* button on left hand side block */}</Form>
              <Select
                placeholder={
                  <FormattedMessage id="RegistrationPeriodConfirmCodeFilter" />
                }
                onChange={handleChangeIsHasConfirmCode}
                style={{ width: 200 }}
                allowClear={true}
              >
              <Option value={true}>
                {
                  <FormattedMessage id="RegistrationPeriodConfirmCodeFilterWithConfirmCode" />
                }
              </Option>
              <Option value={false}>
                {
                  <FormattedMessage id="RegistrationPeriodConfirmCodeFilterWithoutConfirmCode" />
                }
              </Option>
              </Select>
            </Space>
            <Space wrap>
              {auth.can(ACTION.Create, "RegistrationPeriod") ? (
                <Button type="primary" onClick={OpenModalAddRegistrationPeriod}>
                  <Space>
                    <PlusOutlined />
                    <FormattedMessage id="RegistrationPeriodRegistrationPeriodContentAddButton" />
                  </Space>
                </Button>
              ) : null}
            </Space>
          </Space>
          <Table
            bordered
            dataSource={[...state.items]}
            columns={columns}
            rowKey={(r) => r.id}
            pagination={state.pagination}
            loading={state.loading}
            onChange={handleTableChange}
          />
        </Space>
      </Card>
      <Modal
        visible={createModalVisible}
        title={
          <FormattedMessage id="RegistrationPeriodRegistrationPeriodModalAddTitle" />
        }
        okText={<FormattedMessage id="StaticCreateButton" />}
        cancelText={<FormattedMessage id="StaticCancelButton" />}
        onOk={handleAddQrCode}
        onCancel={handleCancelAddQrCode}
        destroyOnClose={true}
      >
        <Form layout="horizontal" form={createForm} {...formItemLayout}>
          <Form.Item name="clientGroupId" hidden>
            <Input />
          </Form.Item>
          <Form.Item
            name="quota"
            label={
              <FormattedMessage id="RegistrationPeriodRegistrationPeriodStaticQuotaLabel" />
            }
            rules={[]}
          >
            <Input type="number"></Input>
          </Form.Item>
          <Form.Item
            name="startOn"
            label={
              <FormattedMessage id="RegistrationPeriodRegistrationPeriodStaticStartDateLabel" />
            }
            rules={[
              {
                required: true,
                message: (
                  <FormattedMessage id="RegistrationPeriodRegistrationPeriodStaticMissingStartDateRule" />
                ),
              },
            ]}
          >
            <DatePicker showTime format="YYYY-MM-DD HH:mm:ss" />
          </Form.Item>
          <Form.Item
            name="endOn"
            label={
              <FormattedMessage id="RegistrationPeriodRegistrationPeriodStaticEndDateLabel" />
            }
            rules={[
              {
                required: true,
                message: (
                  <FormattedMessage id="RegistrationPeriodRegistrationPeriodStaticMissingEndDateRule" />
                ),
              },
            ]}
          >
            <DatePicker showTime format="YYYY-MM-DD HH:mm:ss" />
          </Form.Item>
          <Form.Item
            name="hasEmailNotification"
            label={
              <FormattedMessage id="RegistrationPeriodRegistrationPeriodStaticEmailNotificationLabel" />
            }
            valuePropName="checked"
            initialValue={true}
          >
            <Switch />
          </Form.Item>
          <Form.Item
            name="hasLoginToken"
            label={
              <FormattedMessage id="RegistrationPeriodRegistrationPeriodStaticAutoLoginLabel" />
            }
            valuePropName="checked"
            initialValue={true}
          >
            <Switch />
          </Form.Item>
          <Form.Item
            name="hasConfirmCode"
            label={
              <FormattedMessage id="RegistrationPeriodRegistrationPeriodStaticConfirmCodeLabel" />
            }
            valuePropName="checked"
            initialValue={true}
          >
            <Switch />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        visible={qrcodeModalVisible}
        title={
          <FormattedMessage id="RegistrationPeriodRegistrationPeriodModalReadTitle" />
        }
        okText={<FormattedMessage id="StaticDownloadButton" />}
        cancelText={<FormattedMessage id="StaticCancelButton" />}
        onOk={handleDownloadQrCode}
        onCancel={handleCancelDownloadQrCode}
        destroyOnClose={true}
      >
        <div>
          <img src={qrCodeDataUrl} />
        </div>
      </Modal>
      <Modal
        visible={editModalVisible}
        title={
          <FormattedMessage id="RegistrationPeriodRegistrationPeriodModalEditTitle" />
        }
        okText={<FormattedMessage id="StaticUpdateButton" />}
        cancelText={<FormattedMessage id="StaticCancelButton" />}
        onOk={handleEditQrCode}
        onCancel={handleCancelEditQrCode}
        destroyOnClose={true}
      >
        <Form layout="horizontal" form={editForm} {...formItemLayout}>
          <Form.Item name="id" hidden>
            <Input />
          </Form.Item>
          <Form.Item
            name="quota"
            label={
              <FormattedMessage id="RegistrationPeriodRegistrationPeriodStaticQuotaLabel" />
            }
            rules={[]}
          >
            <Input type="number"></Input>
          </Form.Item>
          <Form.Item
            name="startOn"
            label={
              <FormattedMessage id="RegistrationPeriodRegistrationPeriodStaticStartDateLabel" />
            }
            rules={[
              {
                required: true,
                message: (
                  <FormattedMessage id="RegistrationPeriodRegistrationPeriodStaticMissingStartDateRule" />
                ),
              },
            ]}
          >
            <DatePicker showTime format="YYYY-MM-DD HH:mm:ss" />
          </Form.Item>
          <Form.Item
            name="endOn"
            label={
              <FormattedMessage id="RegistrationPeriodRegistrationPeriodStaticEndDateLabel" />
            }
            rules={[
              {
                required: true,
                message: (
                  <FormattedMessage id="RegistrationPeriodRegistrationPeriodStaticMissingEndDateRule" />
                ),
              },
            ]}
          >
            <DatePicker showTime format="YYYY-MM-DD HH:mm:ss" />
          </Form.Item>
        </Form>
      </Modal>
      {modalContextHolder}
      {messageContextHolder}
    </Layout>
  );
};

export default GroupLevelRegistrationPeriodPage;
